import { FloatingWhatsApp } from '@carlos8a/react-whatsapp-floating-button';
import logo from './img/logo.png';

const App = () => {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber='3102230404' // Required
        accountName='Academia internacional' // Optional
        avatar={logo} // Optional
        initialMessageByServer='Hola como podemos ayudarte?' // Optional
        statusMessage='Disponible' // Optional
        placeholder='Escribe...' // Optional
        allowEsc={true} // Optional
        // Explore all available props below
      />
    </div>
  );
};

export default App;