import React from 'react'

export const Prograweb = () => {
  return (
    <div>
     <div className='tituloelectricidad'>
<h1 class="animate__animated animate__bounceInUp" >Programación Web</h1>
</div>
<p className='textoelectricidad'>
<h2>Contenido:</h2>
<br/>
	Módulo 1 <br/>
	HTML<br/>
	Introducción<br/>
	Estructura html 5 <br/>
	Etiquetas especiales  <br/>
<br/>
	Módulo 2<br/>
	CSS<br/>
	Entorno grafico css <br/>
	Java script <br/>
	Funciones con java script <br/>
<br/>
	Módulo 3<br/>
	Php <br/>
	Php myadmin <br/>

	Módulo 4<br/>
	React- app <br/>
	Estructura react app <br/>
	Comandos especial react app <br/>
	Sass  diseño gráfico de entornos<br/>
<br/>
* CERTIFICADO PARA EDUCACION SUPERIOR<br/>
 Edad mínima 09 años en adelante<br/>
Horarios:<br/>
* 1 vez a la semana / 1 ½ Hora por clase<br/>
* 06 Meses – 36 Hrs.<br/>
Costo: $ 2.400.000,00 (cada módulo $400.000)<br/>
Matricula: $ 60.000,00 (incluye Camibuso)<br/>
Forma de Pago:<br/>
* 1er. Pago $ 460.000,00<br/>
* Pago mensual $ 400<br/>

</p>







    </div>
  )
}
export default Prograweb;