import React from 'react'
import 'animate.css';
import robot from '../components/img/ele1.jpeg';
export const Electricidad = () => {
  return (
    <div>
       <head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
</head>

<div className='tituloelectricidad'>
<h1 class="animate__animated animate__bounceInUp" >ELECTRICIDAD RESIDENCIAL E INDUSTRIAL</h1>
</div>
<p className='textoelectricidad'>CERTIFICADO Y CONTE <br/>
Contenido: <br/><br/>
	Módulo 1 - Electrotecnia y cálculos eléctricos <br/>
		Términos técnicos <br/>
		Leyes de la electricidad<br/>
		Ejercicios prácticos de potencia y tabla capacidad de corriente <br/>
		Clases de corriente (corriente Alterna) y Tipos de Conexión <br/>
		Simbología y elementos eléctricos <br/>
		Conexión elementos eléctricos<br/><br/>
	Módulo 2 – RETIE<br/>
		Reglamento Técnico de Instalaciones Eléctricas - Norma NTC 2050<br/>
		Protección de las instalaciones de uso final - Acometidas <br/>
		Mantenimiento y conservación de instalaciones para uso final<br/>
		Instalaciones básicas<br/>
		Clasificación de las instalaciones de uso final<br/>
		Instalaciones en instituciones de asistencia médica<br/>
		Código de colores - Listado de normas aplicables<br/><br/>
	Módulo 3 – Transformador y Motores<br/>
		Clases y conexiones de transformadores<br/>
		Clases y conexiones de motores<br/>
		Diseño de arranque de motores<br/><br/>
	Módulo 4 – Prácticas<br/>
		Diseño y conexión de elementos eléctricos<br/>
		Diseño y conexión de diferentes tipos de motores<br/>
		Diseño y conexión de arranque de motores<br/>
	Módulo 5 – Proyecto de grado<br/>
		Trabajo en grupo para el </p>

		<div class="animate__animated animate__bounceInRight">
<center><img  className='robot' src={robot}/>  </center>
</div>
      </div>
  )
}
export default Electricidad;