import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'animate.css';

import robot from '../components/img/ro1.jpeg';
const robotica = () => {
  return (
    <div>
        <head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
</head>
<div className='titulorobotica'>
<h1 class="animate__animated animate__bounceInUp" >ROBOTIKA KIDS</h1>
</div>

 <h5 className='titulorobotica'>INNOVACIÓN PARA NIÑAS Y NIÑOS</h5> 
 <br/>
<p className='textorobotica'>
Este curso está diseñado para niños y niñas interesados en adquirir conocimientos técnicos y profesionales en el área de la robótica,<br/>
 programación entre otros.La Robótica facilita que los niños aprendan conceptos científicos como la fuerza, la energía o la tensión<br/> de forma muy sencilla, a la vez que mejor el pensamiento crítico, la colaboración grupal, estimula y desarrolla destrezas cognitivas.
</p>
<br/>
<br/>
<br/>
<br/>
<div class="animate__animated animate__bounceInRight">
<center><img  className='robot' src={robot}/>  </center>
</div>
    </div>
  )
}
export default robotica;