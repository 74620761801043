import React from "react";

import Video from "./vi.mp4";

function App() {
  return (
    <div className="video2">
        <video className="v1" controls autostart autoPlay loop src={Video} type="video/mp4" />
    </div>
  );
}

export default App; 