import React from 'react'
import { Outlet, Link } from 'react-router-dom'
//import Social from './Social';
import facebook from './img/facebook.png';
import instagram from './img/instagram.png';

export const Bajo = () => {
  return (
    
    <div className='futer'>
      <img  className='icono1'  src={facebook}  href="https://www.facebook.com/profile.php?id=100089343387762&mibextid=ZbWKwL"  />

      
      <img className='icono1' src={instagram} />
  {/* <Social/>   */}

<p>ACADEMIA INTERNACIONAL DE PROFESIONALES</p>

<p className='info'>Tel:3102230404  <br/>Av. 5a Nte. #23DN-53, San Vicente, Cali, Valle del Cauca, Colombia</p>

    </div>
  )
  
}


