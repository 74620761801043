import React from 'react'
import '../App.scss';
export const Separador1 = () => {
  return (
    <div className='separador'>


<br/><br/><br/><br/>



    </div>
  )
}
