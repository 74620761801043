
import './App.scss';

import Menu  from './components/Menu';
import Menu2  from './components/Menu2';
import Tarjeta  from './components/Tarjeta';
import {Separador1} from './components/Separador1';
import {Bajo} from './components/Bajo';
import Wha from './components/Wha';
import Texani from './components/Texani';
import Ani from './components/Ani';
import Carousel from './components/Carrusel';
import Video from './components/Video';
import Video2 from './components/Video2';
import Inicio from './pages/Inicio';
import { Routes, Route } from 'react-router-dom';
//import Bajo from './components/Bajo';
//import {Fo} from './components/Fo';
import  Layout  from './pages/Layout';
import  Robotica  from './pages/Robotica';
import  Electricidad  from './pages/Electricidad';
import  Prograweb  from './pages/Prograweb';
import { Outlet, Link } from 'react-router-dom'

//import Social from './components/Social';


function App() {
  return (
    
    <div className="App">
      {/* <Menu/> */}
      {/* <Menu2/> */}
   
   
      <Routes>
        {/* <Link to="Robotica">BLOCK</Link> */}

<Route path='/' element={<Layout/> }>

<Route path='robotica' element={<Robotica />}/>
<Route path='Electricidad' element={<Electricidad/>}/>
<Route path='Prograweb' element={<Prograweb/>}/>

</Route>
      </Routes>
    <Video2/>
      {/* <Carousel/> */}
      <Separador1/>
      {/* <Inicio/> */}
      <Separador1/>
      <Tarjeta/>
      <Separador1/>
      <Bajo/> 
      <Wha/>
     

      {/* <Texani/> */}
      {/* <Ani/> */}
      
     {/* <Fo/> */}
    </div>
  );
}

export default App;
