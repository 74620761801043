import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import foto1 from './img/elect2.jpeg';
import foto2 from './img/robt2.jpeg';
import foto3 from './img/juegos.jpeg';
import foto4 from './img/tecnico.jpeg';
function CardExample() {
  return (
    <div className="d-flex justify-content-around">
      <Card style={{ width: '20%', height: '20%' }}>
        <Card.Img variant="top" src={foto1} />
        <Card.Body>
          <Card.Title><br/>Electricidad Residencial e Industrial</Card.Title>
          <Card.Text>
          {/* ⚡ Curso de Electricidad
Residencia e Industrial
Inicio en Agosto:
Sábados de 1pm. a 5pm.
Solo dura 4 meses...

Inicio en Septiembre:
de lunes a viernes, 
de 6pm a 8.30pm. 
Solo dura 3 meses...

Certificado por competencia laboral y tramite de la tarjeta profesional CONTE

Costo total $ 2.100.000  pregunta como financiarlo. */}
          </Card.Text>
          <Button variant="primary">Mas informacion</Button>
        </Card.Body>
      </Card>


      <Card style={{ width: '20%', height: '20%' }}>
        <Card.Img variant="" src={foto2} />
        <Card.Body>
          <Card.Title>Robotika kids</Card.Title>
          <Card.Text>
          {/* Edad mínima 05 años en adelante
Horarios:
* 1 vez a la semana / 2 ½ Hora por clase
* 04 Meses – 40 Hrs.  (03 niveles – 04 módulos por nivel)
Costo: $ 1.400.000,00 por Nivel (cada módulo $350.000)
Matricula: $ 60.000,00 (incluye Camibuso)
Forma de Pago:
* 1er. Pago $ 410.000,00
* Pago mensual $ 350.000,00 */}

          </Card.Text>
          <Button variant="primary">Mas informacion</Button>
        </Card.Body>
      </Card>


      <Card style={{ width: '20%', height: '20%' }}>
        <Card.Img variant="top" src={foto3} />
        <Card.Body>
          <Card.Title>Desarrollo de Video Juegos</Card.Title>
          <Card.Text>
          {/* * 2 veces a la semana / 2 Horas por clase
* 06 Meses – 96 Hrs.
Costo: $ 2.100.000,00 (cada módulo $350.000)
Matricula: $ 60.000,00 (incluye Camibuso) */}

          </Card.Text>
          <Button variant="primary">Mas informacion</Button>
        </Card.Body>
      </Card>

      <Card style={{ width: '20%', height: '50%' }}>
        <Card.Img variant="top" src={foto4} />
        <Card.Body>
          <Card.Title>Técnico en Sistemas y Programación</Card.Title>
          <Card.Text>
          {/* Aprende a hacer mantenimiento 
          a los equipos de computo, 
          desarrolla apps con 
          los diferentes lenguajes de
          programación web. */}
          </Card.Text>
          <Button variant="primary">Mas informacion</Button>
        </Card.Body>
      </Card>

   

    </div>
  );
}

export default CardExample;