import React, { Component } from 'react'
import { Outlet, Link } from 'react-router-dom'
import logo from '../components/img/logo.png';
import Inicio from '../pages/Inicio';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import Button from 'react-bootstrap/Button';

 const Layout = () => {
  return (


    <div className='boder' >
   
      <nav >
       
        <ul className='menu1'>
        <img className='logo' src={logo}/>    
<a className='menuarriba'>

 {/* <Button  color="primary" variant="dark" disableElevation  >
  Inicio
</Button> */}

<Link to="/" >
INICIO
 </Link>
</a>
<a>
<Link to="/Robotica" >

  Robotica


 </Link>
 </a>
 <a>
<Link to="/Electricidad" >
Electricidad

 </Link>
 </a>
 <a>
<Link to="/Prograweb" >
Programacion web

 </Link>
 </a>

        </ul>
       
       
        </nav>  
     
     {/* <hr/> */}
  
     
        <Outlet/>
        </div>
       
  )
}
export default Layout;